import { cn } from '@/lib/utils';

export function TypographyH3({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<h3
			className={cn(
				'scroll-m-20 capitalize text-3xl lg:text-4xl leading-relaxed font-extrabold font-title tracking-tight first:mt-8 mt-4 mb-4',
				className
			)}>
			{children}
		</h3>
	);
}
