import {
	AreaChart,
	Building,
	Cpu,
	Cross,
	GraduationCap,
	Handshake,
	Siren,
	Slice,
	Target,
	UserCheck,
} from 'lucide-react';
import { ServiceType } from './blocks/Services/types';

export type CaseStudy = {
	logo: string;
	title: string;
	summary: string;
	client: string;
	image: string;

	content: string;
	banner: string;
	slug: string;
	goals: string[];
	accomplishments: {
		title: string;
		description: string;
	}[];
	services: Partial<ServiceType>[];
	meta: {
		title: string;
		description: string;
		image: string;
	};
};

export const caseStudies: CaseStudy[] = [
	{
		logo: '/logos/ascension-logo.png',
		title:
			"How OMNIUX Health Care increased Ascension Health's patient flow by 22%",
		summary:
			"OMNIUX Health Care optimized data capture and patient flow tracking across 139 hospitals. Collaborations with Ascension's networks resulted in significant cost savings and reduced length of stay.",
		client: 'Ascension Health',
		image: '/case-studies/ascension-image.jpg',
		content:
			'OMNIUX Health Care successfully implemented a new data tracking dashboard designed to improve patient flow, reduce average length of stay, and restructure Case Management & Physician Advisory operations. The new system saved Ascension Health over $16 million in just 4 months and has been incorporated into 139 healthcare facilities across the United States.',
		banner: '/case-studies/Ascension-health-case-study-banner.png',
		slug: 'ascension-health',
		services: [
			{
				title: 'Technology Solutions',
				icon: <Cpu size={24} />,
				slug: 'operations-optimization',
				summary:
					'Enable data capture with AI and prescriptive analytics to address operational challenges in real-time.',
			},
			{
				title: 'Emergency & Trauma Services',
				icon: <Siren size={24} />,
				slug: 'comprehensive-healthcare-management',

				summary:
					'Boost patient satisfaction through reduced length of stay and decrease Left Without Being Seen (LWBS) rates.',
			},
			{
				title: 'Surgical Services & Procedural Areas',
				icon: <Slice size={24} />,
				slug: 'comprehensive-healthcare-management',

				summary:
					'Improve your average case margin and decrease room turnover time through advanced scheduling.',
			},
			{
				title: 'Care Coordination Services',
				icon: <Cross size={24} />,
				slug: 'comprehensive-healthcare-management',

				summary:
					'Compliance, best practice, and regulatory standards to reduce risks and throughput, and denied claims.',
			},
			{
				title: 'Cross-Department Coordination',
				icon: <Building size={24} />,
				slug: 'comprehensive-healthcare-management',

				summary:
					'Standardization, staff, scheduling, and deliveries based on national best practice.',
			},
		],
		goals: [
			'Reduce cost of care',
			'Identify key bottlenecks in the patient flow process',
			'Increase rate of patient discharges',
			'Improve overall patient experience',
		],
		accomplishments: [
			{
				title: 'Reduced average length of stay',
				description:
					'7% reduction in average length of stay across all facilities',
			},
			{
				title: '$16 Million saved',
				description: 'Saved over $16 million in 4 months',
			},
			{
				title: 'Implemented Dashboard',
				description:
					'Dashboard technology deployed in 139 healthcare facilities across the United States',
			},
			{
				title: 'Patient Flow Tracking',
				description: 'Dramatically improved patient flow tracking',
			},
			{
				title: 'Restructured Case Management & Physician Advisory operations',
				description: '',
			},
		],
		meta: {
			title:
				'Increasing Patient Flow By 22% With Ascension Health - Case Studies | OMNIUX Health Care',
			description:
				"OMNIUX Health Care optimized data capture and patient flow tracking across 139 hospitals. Collaborations with Ascension's networks resulted in significant cost savings and reduced length of stay.",
			image: '/case-studies/Ascension-health-case-study.png',
		},
	},
	{
		logo: '/case-studies/tenet-health-logo.png',
		title: 'Achieving the Circle of Exellence Award with Tenet Health',
		client: 'Tenet Health',
		content:
			'OMNIUX Health Care Implemented process improvements to drive excellence across the organization. We delivered comprehensive education to all leaders on process improvement methodologies - including advanced training for six leaders to attain Six Sigma Green Belt certification. \n\n We helped establish clear expectations for leaders to champion quality, safety, or financial measures, fostering a culture of high accountability led by senior leadership. The outcomes included elevated quality metrics and improved throughput, resulting in a reduction in readmissions and enhanced financial performance.',
		summary:
			'OMNIUX Health Care worked with Tenet Health to improve operational efficiency for 14 hospitals across 3 health systems in the Arizona.',
		image: '/case-studies/tenet-health-image.jpg',
		banner: '/case-studies/Honor-health-case-study-banner.png',
		slug: 'tenet-health',
		goals: [
			'Develop and implement key strategic operational initiatives.',
			'Provide Population Health Management.',
			'Facilitate optimal care delivery.',
			'Deliver safe, high quality care.',
		],
		accomplishments: [
			{
				title: 'Tenet Circle of Excellence Award',
				description: 'Received in 2017 & 2018 for high quality outcomes.',
			},
			{
				title: '12% Increase in Staff Retention',
				description:
					'Achieved a 12% increase in staff retention across all facilities.',
			},
			{
				title: 'Grade A Leapfrog Saftey Rating',
				description: 'Leapfrog rating improved from C to A ',
			},
			{
				title: '4 Star CMS Rating',
				description: 'CMS star rating improved from 2 to 4',
			},
		],
		meta: {
			title:
				'Achieving The Circle Of Exellence Award With Tenet Health - Case Studies | OMNIUX Health Care',
			description:
				'OMNIUX Health Care worked with Tenet Health to improve operational efficiency for 14 hospitals across 3 health systems in the Arizona.',
			image: '/case-studies/tenet-health-case-study.png',
		},
		services: [
			{
				title: 'Marketing & Branding',
				slug: 'patient-employee-satisfaction',

				icon: <Target size={24} />,
				summary:
					'Boost your brand and attract top talent with our marketing and branding strategies.',
			},
			{
				title: 'Refining the Patient Experience',
				slug: 'patient-employee-satisfaction',

				icon: <UserCheck size={24} />,
				summary:
					'Enhance patient outcomes through practice modeling, education, and evidence-based tactics.',
			},
			{
				title: 'Education',
				slug: 'patient-employee-satisfaction',

				icon: <GraduationCap size={24} />,
				summary:
					'Improve staff competence and efficiency with evidence-based programs aligned with national care standards.',
			},
			{
				title: 'Employee Engagement',
				slug: 'patient-employee-satisfaction',

				icon: <Handshake size={24} />,
				summary:
					'Comprehensive leadership support to enhance staff retention, engagement, and satisfaction.',
			},
		],
	},
];
