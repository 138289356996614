'use client';

import { Block } from '../../components/Block';
import { Gutter } from '../../components/Gutter';
import { TypographyH2 } from '@/components/ui/h2';
import { motion } from 'framer-motion';
import { TypographyH3 } from '@/components/ui/h3';
import { TypographySmall } from '@/components/ui/small';
import Link from 'next/link';
import { caseStudies } from '@/case-studies';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import Image from 'next/image';
import { TypographyP } from '@/components/ui/p';
import { TypographyLead } from '@/components/ui/lead';
import { Button } from '@/components/ui/button';
import { ChevronRight } from 'lucide-react';
import { FC } from 'react';

const CaseStudies: FC<{ id?: string }> = ({ id }) => {
	return (
		<div className='relative'>
			{caseStudies.slice(0, 2).map((x, i) => (
				<Block id={id} animate key={x.slug} className='relative'>
					<Gutter
						padding='large'
						className='h-full gap-8 lg:gap-24 grid lg:grid-cols-2'>
						<div
							className={
								i % 2 === 0
									? 'order-last lg:order-none'
									: 'order-last lg:order-last'
							}>
							<TypographyH2>Case study</TypographyH2>
							<TypographyH3>{x.title}</TypographyH3>
							<TypographyLead>{x.summary}</TypographyLead>
							<Button
								variant='outline'
								el='link'
								href={`/case-studies/${x.slug}`}
								className='mt-8 group w-min lg:mx-auto'>
								Read More
								<ChevronRight
									size={24}
									className='ml-2 group-hover:ml-4 duration-150 ease-in-out'
								/>
							</Button>
						</div>
						<div className='w-full h-80 lg:h-64 relative'>
							<Link href={`/case-studies/${x.slug}`}>
								<Image
									className='rounded-lg'
									fill
									src={x.banner}
									alt={x.title}
								/>
								<Image
									alt={x.client}
									src={x.logo}
									className='absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 '
									height={300}
									width={300}
								/>
							</Link>
						</div>
					</Gutter>
				</Block>
			))}
		</div>
	);
};

export default CaseStudies;
