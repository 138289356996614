import { cn } from '@/lib/utils';
import { FC } from 'react';

const paddingClasses = {
	none: '',
	small: 'py-6 lg:py-4',
	medium: 'py-12 lg:py-16',
	large: 'lg:py-32 py-16',
	hero: 'py-16 lg:py-48',
};

export const Gutter: FC<{
	children?: React.ReactNode;
	className?: string;
	padding?: keyof typeof paddingClasses;
}> = ({ children, className, padding = 'small' }) => {
	return (
		<div
			className={cn(
				'max-w-6xl w-full px-3 lg:px-3',
				paddingClasses[padding],
				className
			)}>
			{children}
		</div>
	);
};
