import { cn } from '@/lib/utils';

export function TypographyH2({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<h2
			className={cn(
				'inline-block capitalize rounded-sm bg-secondary px-3 py-1 text-base text-foreground',
				className
			)}>
			{children}
		</h2>
	);
}
