'use client';

import { cn } from '@/lib/utils';
import { FC } from 'react';
import { AnimatedBlock } from './AnimatedBlock';

const paddingClasses = {
	none: '',
	small: 'py-6 lg:py-4',
	medium: 'py-12 lg:py-8',
	large: 'lg:py-32 py-16',
	hero: 'py-16 lg:py-48',
};

export const Block: FC<{
	children?: React.ReactNode;
	className?: string;
	id?: string;
	animate?: boolean;
	padding?: keyof typeof paddingClasses;
}> = (props) => {
	const { children, className, id, animate, padding = 'none' } = props;

	let Component = undefined;

	if (animate === true) {
		Component = <AnimatedBlock {...props}>{children}</AnimatedBlock>;
	} else {
		Component = (
			<div id={id} className='flex flex-col items-center'>
				{children}
			</div>
		);
	}

	return (
		<div id={id} className={cn(className, paddingClasses[padding])}>
			{Component}
		</div>
	);
};
